import React from 'react'
import PropTypes from 'prop-types'
import ContactInfo from './ContactInfo'
import { ContactUsDiv } from './styled'
import { FooterTitle, Title } from '../styled'

const GroupContactUs = ({data, classname}) => {
  const widgets = data.widgets

  const findTitle = () => {
    const result = widgets.find(function(side) {
      return side.type === "text"
    })

    if(!result) return data.name

    return result.value?.rendered
  }

  const title = findTitle()

  return (
    <ContactUsDiv className={classname || ''} key={`contactDiv_${Math.random()}`}>
      <FooterTitle className="contact-footer">
        <Title dangerouslySetInnerHTML={{ __html: title }} />
      </FooterTitle>

      {widgets.map(widget => {
        if (widget.type !== 'footer_information') return null

        const widgetValues = JSON.parse(widget.value.rendered)

        return (
          <ContactInfo
            key={`contact_${widgetValues.title}_${Math.random()}`}
            icon={widgetValues.icon}
            title={widgetValues.title}
            text={widgetValues.text}
            subtext={widgetValues.subtext}
          />
        )
      })}
    </ContactUsDiv>
  )
}

GroupContactUs.propTypes = {
  title: PropTypes.string,
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.object.isRequired,
      text: PropTypes.string.isRequired,
      subtext: PropTypes.string.isRequired,
    })
  ),
}

export default GroupContactUs
