import styled from 'styled-components'
import media from 'styled-media-query'

const GroupLinksDiv = styled.div`
  display: flex;
  justify-content: space-between;
  
  ${media.lessThan('medium')`
    display: none;
  `}
`

const FooterTitle = styled.div`
  color: #D12673;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 1.6rem;

  &.contact-footer {
    ${media.lessThan('medium')`
      margin-bottom: 3.2rem;
    `}
  }
`

export const Title = styled.p`
  margin: 0;
`

export { GroupLinksDiv, FooterTitle }
